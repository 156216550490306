<template>
  <v-container class="max-container">
    <top-banner :cancel="cancel" />
    <v-card class="payment-box" flat dark color="black">
      <add-payment-form ref="paymentForm" :title="title" />
      <v-btn block depressed class="tile text-capitalize channel-button btn-add-payment" :loading="loading" @click="addPayment">Complete</v-btn>
      <div v-if="trial7day && can_free_trial && !payGoUser" class="trial-box">
        <v-btn class="ma-2 text-capitalize" small text color="darken-1" @click="trial7dayHandle">Skip, try for 3 day</v-btn>
      </div>
      <div class="authcard-message">
        We will now hold ${{ try_charge_amount/100 }} from your card, it won't be charged until your subscription becomes active. You can cancel at anytime<br v-if="tip_text">
        Your card details are sent to Stripe via secure SSL connection for payment processing.We do not store your card information in our servers.
      </div>
    </v-card>
    <over-lay-loading ref="overlayloading" />
    <c-snack-bar ref="csnackbar" />
    <over-dialog ref="overdialog" :ok-handle="createSub" />

    <confirm-dialog ref="confirmdialog" :cancel-handle="backSelect" :ok-handle="closeDialog" />
  </v-container>
</template>

<script>

// eslint-disable-next-line
import { subscription } from '@/api/user'
import TopBanner from './components/topBanner'

import OverLayLoading from '@/views/auth/components/overLayLoading'
import PaymentMixin from '@/mixins/paymentMixin'
import ConfirmDialog from './components/overDialog'

import { setDeviceFrom } from '@/util/auth'

import logo from '@/assets/logo.png'

export default {
  name: 'Payment',
  components: {
    TopBanner,
    OverLayLoading,
    ConfirmDialog
  },
  mixins: [PaymentMixin],
  data: () => ({
    loading: false,
    logo: logo,
    tip_text: ''
  }),
  computed: {
    can_free_trial() {
      return this.$store.state.user.userInfo.can_free_trial
    },
    title() {
      const sub = this.$store.state.user.userInfo.subscription
      const payGoUser = this.$store.state.user.userInfo.pay_as_go_user
      if (!sub && !payGoUser) {
        return 'Add a credit card now to have 7 days free trial!'
      } else if (sub && sub.status === 'trial_1' && !payGoUser) {
        return 'Add a credit card now to have 7 days free trial!'
      } else {
        return 'Set up your credit or debit card'
      }
    },
    is_mobile_app() {
      if (window.isAndroidWebView()) {
        return true
      }

      if (window.isWebKit()) {
        return true
      }
      return false
    }
  },
  created() {
    const from = this.$route.query.from
    if (from) {
      setDeviceFrom(from)
    }
    this.getTextByCode()
  },
  methods: {
    trial7dayHandle() {
      // TODO: create trial_1 subscription
      if (this.trial7day) {
        if (this.$store.state.user.sub_data.length > 1) {
          this.$refs.confirmdialog.controlDialog(
            true,
            'You can only try a single channel without a card. Add a card to try all channels.',
            'Confirm',
            true,
            true,
            'Add Card',
            'Reselect'
          )
          return
        }

        if (this.$store.state.user.sub_data.find(item => ['paramount plus', 'prime video', 'hbo'].includes(item.platform_name))) {
          this.$refs.confirmdialog.controlDialog(
            true,
            `${this.$store.state.user.sub_data[0].platform_name} is limited to member users only, please add payment method.`,
            'Confirm',
            true,
            true,
            'Add Card',
            'Reselect'
          )
          return
        }

        this.$refs.overlayloading.controlShow(true)
        const platform_list = this.$store.state.user.sub_data.map(item => item.platform_name)
        const operations = this.newUser ? 'trial_1' : 'update'
        subscription({ 'operations': operations, 'platform_list': platform_list, 'interval_count': this.$route.query.st }).then(async(res) => {
          this.$refs.overlayloading.controlShow(false)
          this.showNackBar('info', res.message)
          if (res.code === 0) {
            if (this.newUser) {
              this.$router.push({ name: 'Referral', query: { 'ft': 'register' }})
              return
            }
            if (window.isWebKit()) {
              window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
              return
            }
            if (window.isAndroidWebView()) {
              // eslint-disable-next-line no-undef
              window.AndroidCallback.onSubscriptionCallback('success')
              return
            }
            await this.$store.dispatch('user/getInfo')
            this.$router.push({ name: 'InstallApp' })
            return
          }
        }).finally(() => {
          this.$refs.overlayloading.controlShow(false)
        })
      }
    },
    async goTo() {
      if (this.newUser) {
        this.$router.push({ name: 'Profile' })
        return
      }

      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      await this.$store.dispatch('user/getInfo')
      this.$router.push({ name: 'mychannel' })
    },
    cancel() {
      this.$router.push({ name: 'mychannel' })
    },
    backSelect() {
      this.$router.push({ path: '/auth/subscription' })
    },
    closeDialog() {
      this.$refs.confirmdialog.controlShowDialog(false)
    }
  }
}
</script>
<style scoped lang="scss">

.v-card__subtitle, .v-card__text, .v-card__title{
  padding-left: 2px!important;
  padding-bottom:2px;
  padding-right: 0px;
}

.container{
  text-align: -moz-center;
  text-align: center;
  text-align: -webkit-center;
}

.payment-box{
  max-width: 380px;
}

.trial-box{
  text-align: center;
  .v-btn{
    font-weight: bold;
    letter-spacing: 0.08em;
    color: #E040FB;
    font-size: 13px;
    text-decoration-line: underline;
  }
}

.tip-box{
  width: 100%;
  height: 50px;
  background: #EFEBE9;
}

.channel-button {
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.card-title-text{
  color: #eae5e5!important;
}

.authcard-message{
  padding-top: 10px;
  text-align: initial;
  color: rgba(255, 255, 255, 0.7);
}

.spacer-box{
  width: 100%;
  height: 20px;
}
</style>
